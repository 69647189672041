const data = [
    {
        bgi: "/truck5.jpg",
        name: "Customer service",
        title: "Customer Service",
        text:"Mijozlarga xizmat ko'rsatuvchi xodimlari mijozlar va ulardan keladigan shikoyat va savollar bilan ishlaydilar. Bu xizmat orqali mijozlar mahsulot va xizmatlar haqida, buyurtmalarni qabul qilish va qaytarish haqida to'liq ma'lumot olishadi.",
        text2: "Mijozlarga xizmat ko’rsatish ba'zida savdoda asosiy jarayon bo'lib, bu orqali mijozlar mahsulotlar, xizmatlar haqida to’liq tushunchaga ega bo’lib, o'z savollariga javob oladilar.",
        img: "/istock-1130492345-1200x628-1.jpg",
    }
]
export default data;