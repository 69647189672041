import axios from "axios";
import {message} from "antd";

export const SendEmail = (values) => {
    const UserData = {
        fullName: values.user.name,
        phone: values.user.phone,
        email: "ilkhomovgiyos@gmail.com"
    }
    console.log(UserData)
    axios.post("https://send-email-message.herokuapp.com/api/send", UserData).then((response) => {
        if(response.status === 200) {
            message.success("Jo'natildi")
        }
    }).catch((error) => message.error("Jo'natilmadi"))
}