import React from 'react';
import './FirstSection.scss';

const FirstSection = () => {
    return (
        <div className="first_about_section">
            <h1>Biz haqimizda</h1>
        </div>
    );
};

export default FirstSection;
