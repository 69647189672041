import React from 'react';
import './FirstSection.scss';

const FirstSection = ({data}) => {
    console.log(data)
    return (<div className="first_courses_section">
        {data.map((v, i) => {
            return (
                <div>
                    <h1>{v.name}</h1>
                </div>
            )
        })}
    </div>);
};

export default FirstSection;
