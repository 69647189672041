import React from 'react';
import './SectionTitle.scss';

const SectionTitle = (props) => {
    return (
        <div className="section_title">
            <h1 className={`title ${props.className}`}>{props.title}</h1>
            <div className="line"/>
        </div>
    );
};

export default SectionTitle;
