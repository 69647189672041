import React from 'react';
import './FourthSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {Col, Row} from "antd";

const FourthSection = () => {
    return (
        <div className="fourth_section">
            <SectionTitle className="text-white" title="Biz qanday ishlaymiz" />
                <div className="text">
                    <p className="">Biz nafaqat zamonaviy kasblarni o'qitamiz, balkim muvaffaqiyatli bitkazgan talabalarni yaxshi maosh asosida ishga qabul qilamiz, yoki boshqa kompaniyalardan ish topishga amaliy yordam beramiz.</p>
                    <div className="infinity-path">
                        <div></div>
                        <div></div>
                    </div>
                    <Row className="justify-content-around mt-3">
                        <Col span={12} md={8} style={{textAlign:"end"}}>
                            <h4 className="fw-bold">Talaba</h4>
                            <ul>
                                <li>Barqaror ish joyiga ehtiyoj </li>
                                <li> Yuqori daromad</li>
                                <li>Karyera o'sishi</li>
                            </ul>
                        </Col>
                        <Col span={12} md={8} style={{textAlign:"start"}}>
                            <h4 className="fw-bold">Kompaniya</h4>
                            <ul>
                                <li>Kompaniyaning kengayishi</li>
                                <li>Pul aylanmasining o’sishi</li>
                                <li>Innovatsion qarorlar</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
        </div>
    );
};

export default FourthSection;
