import React from 'react';
import './FooterDown.scss';
import SectionTitle from "../../SectionTitle/SectionTitle";
import {Button, Col, Form, Input, Row} from "antd";
import NumberFormat from "react-number-format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {SendEmail} from "../../../api/api";

const validateMessages = {
        required: 'ma\'lumot kiritilmagan!', types: {
        email: '${label} is not a valid email!', number: '${label} is not a valid number!',
    },
};

const FooterDown = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        form.resetFields();
        SendEmail(values);
    };

    return (<div className="footer_down">
        <div className="footer_down_content">
            <SectionTitle className="text-white w-75"
                          title="O'z ma'lumotingizni qoldiring va biz siz bilan bog'lanamiz!"/>
            <Form form={form} className="form mt-4" name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <div className="input_cont">
                    <Row>
                        <Col span={24} md={12}>
                            <Form.Item name={['user', 'name']} rules={[{required: true}]}>
                                <Input className="text-white" placeholder="F.I.O"/>
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item name={['user', 'phone']} rules={[{required: true}]}>
                                <NumberFormat
                                    mask="-"
                                    allowEmptyFormatting
                                    format="+998 ## ### ## ##"
                                    placeholder="99 999 9999"
                                    country="US"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Form.Item>
                    <Button className="submit" type="primary" htmlType="submit">
                        Jo'natish
                    </Button>
                </Form.Item>
            </Form>
        </div>
        <div className="menu">
            <a className="m-0" href="/#section1">
                <img src="/Edutruck3.png" alt=""/>
            </a>
            <div className="">
                <a className="link" href="/#courses">Kurslar</a>
                <a className="link" href="/#benifit">Afzalliklar</a>
                <a className="link" href="/#contact">Aloqa</a>
            </div>
            <div className="">
                <FontAwesomeIcon icon={faTelegram}/>&ensp;<a className="m-0" href="https://t.me/+L2_mVA0F_84xYzU6"
                                                             target="_blank">@eduTruck</a>
                <p className="m-0">www.edutruck.uz</p>
            </div>
        </div>
        <div className="footer_info">
            <p className="">© edutruck.uz | 2022. All rights reserved.</p>
            <p> Powered by <a target="_blank" href="http://revotech.uz/">Revotech, IT-Park</a></p>
        </div>
    </div>);
};

export default FooterDown;
