import React from 'react';
import './FifthSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faLocationDot, faUsers} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "antd";

const data = [
    {
        icon: faUsers,
        title: "Kuchli Jamoa",
        subtitle: "Biz sohaning yetuk mutahassislaridan bilim olish imkoniyatini beramiz."
    },{
        icon: faCheckDouble,
        title: "Amaliyot bilan o'rganish",
        subtitle: "Bilim shunchaki leksiyalar eshitish bilan emas, balki amaliy ko'nikmalar bilan egallanishini yaxshi bilamiz."
    },{
        icon: faLocationDot,
        title: "Qulay joylashuv va vaqt",
        subtitle: "Biz IT shaharda joylashganmiz, va kurslar vaqtini talabdan kelib chiqqan holda belgilaymiz."
    },
]

const FifthSection = () => {
    return (
        <div className="fifth_section" id="benifit">
            <SectionTitle title="Afzalliklarimiz"/>
            <Row className="cards">
                {data.map((v,i) => {
                    return(
                        <Col key={i} className="card_cont mt-5" span={24} sm={12} md={12} lg={8}>
                            <div className="cardd">
                                <div className="icon_cont">
                                    <FontAwesomeIcon className="icon" icon={v.icon}/>
                                </div>
                                <div className="text h-50">
                                    <p className="title fw-bold m-0">{v.title}</p>
                                    <p className="subtitle">{v.subtitle}</p>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
};

export default FifthSection;
