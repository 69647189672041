import React from 'react';
import './ThirdSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {Col, Row} from "antd";
import {Link} from "react-router-dom";

const data = [
    {
        icon: "/output-onlinepngtools (6).png",
        title: "Dispatch",
        description: "Yuk mashinalarini tashish dispetcheri Logistika kompaniyasining moliyaviy natijalariga bevosita ta'sir ko'rsatadigan xodimdir.",
        href: "/dispatch",
    }, {
        icon: "/output-onlinepngtools (7).png",
        title: "Customer Service",
        description: "Mijozlarga xizmat ko'rsatuvchi xodimlar mijozlar va ulardan keladigan shikoyat va savollar bilan ishlaydilar.",
        href: "/customer",
        class: "short_border"
    }, {
        icon: "/output-onlinepngtools (4).png",
        title: "Business Process Outsourcing",
        description: "Business Process Outsourcing (BPO) - bu bir yoki bir nechta IT-intensiv...",
        href: "/business",
        class: "short_border"
    }, {
        icon: "/output-onlinepngtools (1).png",
        title: "Updating",
        description: "Updating department GPS va kartalar bilan ishlaydi va brokerga yuk va uning borishi haqida ma’lumot berib turadi.",
        href: "/updating",
    }, {
        icon: "/output-onlinepngtools (8).png",
        title: "Fleet Management",
        description: "Fleet managerlar Logistikada transport sohasi bo'yicha mutaxassislardir.",
        href: "/management",
        class: "short_border"
    }, {
        icon: "/output-onlinepngtools (11).png",
        title: "ELD Services",
        description: "ELD qisqartmasi elektron ro'yxatga olish qurilmasini anglatadi.",
        href: "/eld",
        class: "short_border"
    }]

const ThirdSection = () => {
    return (<div className="third_section" id="courses">
        <SectionTitle className="text-white" title={"Kurslar"}/>
        <Row className="mt-3">
            {data.map((v, i) => {
                return (
                    <Col key={i} span={24} sm={12} md={12} lg={8} className="card_cont">
                        <div className="">
                            {/*<div className={v.class}/>*/}
                            <div className="cardd d-flex flex-column justify-content-between">
                                <img className="icon" src={v.icon} alt=""/>
                                <p className="fw-bold m-0 align-self-start title">{v.title}</p>
                                <p>{v.description}</p>
                                <Link to={v.href}>
                                    <div className="button" id="button-7">
                                        <div id="dub-arrow"><img
                                            src="https://github.com/atloomer/atloomer.github.io/blob/master/img/iconmonstr-arrow-48-240.png?raw=true"
                                            alt=""/></div>
                                        Batafsil
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Col>)
            })}
        </Row>
    </div>);
};

export default ThirdSection;
