import React, {useRef} from 'react';
import "./MainPage.scss";
import FirstSection from "./sections/FristSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";
import FifthSection from "./sections/FifthSection";
import Footer from "../../components/Footer/Footer";
import FourthSection from "./sections/FourthSection";
import Header from "../../components/Header/Header";
import SixthSection from "./sections/SixthSection";

const MainPage = () => {
    return (
        <div className="mainPage_wrapper">
            <Header/>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <FifthSection/>
            <FourthSection/>
            <SixthSection/>
            <Footer/>
        </div>
    );
};

export default MainPage;
