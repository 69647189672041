import React, {useEffect, useState} from 'react';
import "./Header.scss";
import {Button} from "antd";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faFacebookSquare, faInstagram, faTelegram} from "@fortawesome/free-brands-svg-icons";
import ConsultModal from "./sections/ConsultModal";
import BurgerMenu from "./sections/BurgerMenu";
import {SendEmail} from "../../api/api"

const Header = () => {

    const [header, setHeader] = useState("header");
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }

    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeader("header")
        } else if (window.scrollY > 70) {
            return setHeader("header2")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (<div className={`header_wrapper ${header}`}>
        <div className="contacts justify-content-between text-white ">
            <div className="contacts1">
                <p className="address">Yunusobod, Bodomzor 63</p>
                <span>
            <FontAwesomeIcon className="element" icon={faTelegram}/>&ensp;<a href="https://t.me/+LL2_mVA0F_84xYzU6"
                                                                             target="_blank">@eduTruck</a>
            </span>
                <div className="shake">
                    <FontAwesomeIcon className="element" icon={faPhone}/>&ensp;<a href="tel:+998977604267">+998 97 760
                    42 67</a>&ensp;&ensp;
                </div>
            </div>
            <div className="contacts2 d-flex">
                <p className="icon">FAQ</p>
                <a href="https://www.instagram.com">
                <FontAwesomeIcon className="icon" icon={faInstagram}/>
                </a>
                <a href="https://www.facebook.com">
                <FontAwesomeIcon className="icon" icon={faFacebookSquare}/>
                </a>
            </div>
        </div>
        <div className="menu">
            <a href="/#section">
                <img src={header !== 'header' ? "/Edutruck3.png" : "Edutruck3white.png"} alt=""/>
            </a>
            <div className="hamburger" onClick={toggleHamburger}>
                <BurgerMenu showModal={showModal}/>
            </div>
            <div className="menu_links">
                <Button className="consult" onClick={showModal}>Kursga yozilish</Button>
                <a className="link" href="/#courses">Kurslar</a>
                <a className="link" href="/#benifit">Afzalliklar</a>
                <a className="link" href="/#contact">Aloqa</a>
            </div>
        </div>
        <ConsultModal SendEmail={SendEmail} isModalVisible={isModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel}/>
    </div>);
};

export default Header;
