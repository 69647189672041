import React from 'react';
import './AboutUs.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";

const AboutUs = () => {
    return (<>
        <Header/>
        <div className="about_us">
            <FirstSection/>
            <SecondSection/>
        </div>
        <Footer/>
    </>);
};

export default AboutUs;
