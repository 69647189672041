import dispatch from "./dispatch";
import customer from "./customer";
import business from "./business";
import updating from "./updating";
import management from "./management";
import eld from "./eld";

const data = {
    "dispatch": dispatch,
    "customer": customer,
    "business": business,
    "updating": updating,
    "management": management,
    "eld": eld
}

export default data;