import React from 'react';
import './SecondSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {Col, Row} from "antd";

const SecondSection = () => {
    return (
        <div className="second_about_section">
            <Row>
                <Col span={24} md={12} className="">
            <SectionTitle title="Biz haqimizda" />
                    <p className="text pe-2 pe-lg-5 pt-3">Maqsadimiz yoshlarni zamonaviy kasblardan biri bo’lgan Logistika soxasi bo’yicha professional darajada o’qitish, bilim berish va ularni istiqbolli kelajak sari yo’naltirish.</p>
                    <p className="text pe-2 pe-lg-5 pt-3">5000 ta talabani professional darajada o’qitish va doimiy ish bilan taminlash va har doim ular bilan aloqani saqlab qolish.</p>
                </Col>
                <Col span={24} md={12}>
                    <img src="/truck3.jpg" alt=""/>
                </Col>
            </Row>
        </div>
    );
};

export default SecondSection;
