import './App.scss';
import './assets/scss/global.scss';
import './assets/scss/variables.scss';
import 'antd/dist/antd.css';
import MainPage from "./pages/MainPage/MainPage";
import {Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs from "./pages/AboutUs/AboutUs";
import Courses from "./pages/Courses/Courses";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import {BackTop} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpLong} from "@fortawesome/free-solid-svg-icons";
import React from "react";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/about_us" element={<AboutUs/>}/>
            <Route path="/:courses" element={<Courses/>} />
            <Route path="*" element={<PageNotFound/>} />
        </Routes>
        <BackTop><FontAwesomeIcon className="upIcon" icon={faUpLong} fixedWidth /></BackTop>
    </div>
  );
}

export default App;
