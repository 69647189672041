import React from 'react';
import './FooterUp.scss';
import SectionTitle from "../../SectionTitle/SectionTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FullscreenControl, GeolocationControl, Map, Placemark, YMaps, ZoomControl} from "react-yandex-maps";
import {Col, Row} from "antd";

const FooterUp = () => {
    return (<Row className="footer_up d-flex">
        <Col span={24} lg={12} className="">
            <YMaps>
                <Map className="map"
                    // width={'100%'} height={'100%'}
                    defaultState={{
                    center: [41.340601, 69.299140],
                    zoom: 15,
                }}>
                    <Placemark geometry={[41.340601, 69.299140]} />
                    <ZoomControl />
                    <FullscreenControl options={{float: 'left'}} />
                    <GeolocationControl options={{float: 'right'}} />
                </Map>
            </YMaps>
        </Col>
        <Col span={24} lg={12} className="column2">
            <table>
                <tbody>
                <tr>
                    <td/>
                    <td><SectionTitle title="Aloqa"/></td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <div className="brand">
                            <p className="brand_name">Edutruck</p>
                            <p className="brand_description">Biz bilan birgalikda Logistikaning eng
                                ommabop kurslarini professional darajada o'rganing!</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <FontAwesomeIcon className="icon" icon={faLocationDot}/>
                    </td>
                    <td>
                        <p className="address">
                            Toshkent shahar, Yunusobod tumani, Bog'ishamol 90
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <FontAwesomeIcon className="icon" icon={faClock}/>
                    </td>
                    <td>
                        <p className="working_hours">Du - Ju: 9:00 - 17:00
                            Sha-Ya: dam olish kuni</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <FontAwesomeIcon className="icon" icon={faPhone}/>
                    </td>
                    <td>
                        <p className="">
                            7 383 375 04 27 <br/>
                            7 913 922 11 80
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </Col>
    </Row>);
};

export default FooterUp;
