const data = [
    {
        bgi: "/truck1.jpg",
        name:"Dispatch",
        title: "Dispatch",
        text:"Yuk mashinalarini tashish dispetcheri Logistika kompaniyasining moliyaviy natijalariga bevosita ta'sir ko'rsatadigan xodimdir. Ular yuklarni tanlaydi, drayverlarni boshqaradi, marshrutlarni tanlaydi va yuklarni etkazib berishni nazorat qiladi.",
        text2: "Ya’ni jo'natilishi kerak bo'lgan yukni aniqlash, brokerlar bilan gaplashish, muzokaralar olib borish ham ularning zimmasidadir.",
        img: "/truck1.jpg",
    }
]
export default data;