import React from 'react';
import './SecondSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {Col, Row} from "antd";

const SecondSection = ({data}) => {
    return (
        <div className="second_courses_section">
            {data.map((v, i) => {
                return (<>
                    <Row>
                        <Col span={24} md={12} className="">
                    <SectionTitle title={v.title}/>
                            <p className="text pe-2 pe-lg-5 pt-3">{v.text}</p>
                            <p className="text pe-2 pe-lg-5 pt-3">{v.text2}</p>
                        </Col>
                        <Col span={24} md={12}>
                            <img src={v.img} alt=""/>
                        </Col>
                    </Row>
                </>)
            })}
        </div>
    );
};

export default SecondSection;
