import React, {useEffect, useState} from 'react';
import './FristSection.scss';
import {Link} from "react-router-dom";

const words = ["Dispatch", "Customer Service", "Business Process Outsourcing", "Updating", "Fleet Management", "ELD Services", "courses"];

const FirstSection = () => (<div className="first_section" id="section">
    <div className="main_section">
        <div className="content">
            <div className="content__container">
                <p className="content__container__text">
                    Kurslarimiz:
                </p>

                <ul className="content__container__list">
                    <li className="content__container__list__item">Dispatch</li>
                    <li className="content__container__list__item">Customer Service</li>
                    <li className="content__container__list__item">BPO</li>
                    <li className="content__container__list__item">Updating</li>
                    <li className="content__container__list__item">Fleet Management</li>
                    <li className="content__container__list__item">ELD Services</li>
                </ul>
            </div>
        </div>
        <p className="subtitle">Biz bilan birgalikda Logistikaning eng <br/> ommabop kurslarini professional
            darajada o'rganing</p>
        <Link to="/about_us">
            <div className="button" id="button-7">
                <div id="dub-arrow"><img
                    src="https://github.com/atloomer/atloomer.github.io/blob/master/img/iconmonstr-arrow-48-240.png?raw=true"
                    alt=""/></div>
                Batafsil
            </div>
        </Link>
    </div>
</div>);

export default FirstSection;
