import React, {useState} from 'react';
import './BurgerMenu.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";

const BurgerMenu = (props) => {
    const [open, setOpen] = useState(false);

    const openIcon = <FontAwesomeIcon className="openIcon" icon={faBars} onClick={() => setOpen(!open)}/>;
    const closeIcon = <FontAwesomeIcon className="icon" icon={faXmark} onClick={() => setOpen(!open)}/>;
    return (<div className="hamburger">
        {open && closeIcon || openIcon}
        {open && <div className="bgc">
            <ul className="burger_links text-center">
                {closeIcon}
                <li className="text-center">
                <a className="link" href="/#courses" onClick={()=> setOpen(!open)}>Kurslar</a>
                </li>
                <li>
                <a className="link text-center" href="/#benifit" onClick={()=> setOpen(!open)}>Afzalliklar</a>
                </li>
                <li>
                <a className="link" href="/#contact" onClick={()=> setOpen(!open)}>Aloqa</a>
                </li>
                <li>
                <Button className="consult" onClick={()=> {
                    props.showModal()
                    setOpen(!open)
                }}>Kursga yozilish</Button>
                </li>
            </ul>
        </div>}

    </div>);
};

export default BurgerMenu;
