import React from 'react';
import './Footer.scss';
import FooterUp from "./sections/FooterUp";
import FooterDown from "./sections/FooterDown";

const Footer = () => {
    return (
        <div className="footer_wrapper" id="contact">
            <FooterUp/>
            <FooterDown/>
        </div>
    );
};

export default Footer;
