import React from 'react';
import './ConsultModal.scss';
import {Button, Form, Input, Modal} from "antd";
import NumberFormat from "react-number-format";

const validateMessages = {
    required: 'ma\'lumot kiritilmagan!', types: {
        email: '${label} is not a valid email!', number: '${label} is not a valid number!',
    },
};

const ConsultModal = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        form.resetFields();
        props.SendEmail(values)
        props.handleCancel();
    };

    return (
        <Modal className="consult_modal" footer={null} title="O'z ma'lumotingizni qoldiring biz siz bilan bog'lanamiz"
               visible={props.isModalVisible}
               onOk={props.handleOk} onCancel={props.handleCancel}>
            <Form form={form} name="nest-messages" onFinish={onFinish}
                  validateMessages={validateMessages}>
                <div className="input_cont">
                    <Form.Item
                        name={['user', 'name']}
                        rules={[{
                            required: true,
                        },]}
                    >
                        <Input placeholder="F.I.O"/>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'phone']}
                        rules={[{
                            required: true,
                        },]}
                    >
                        <NumberFormat
                            mask="-"
                            allowEmptyFormatting
                            format="+998 ## ### ## ##"
                            placeholder="99 999 9999"
                            country="US"
                        />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button className="submit" type="primary" htmlType="submit">
                        Jo'natish
                    </Button>
                </Form.Item>
            </Form>
        </Modal>);
};

export default ConsultModal;
