import React from 'react';
import './PageNotFound.scss';

const PageNotFound = () => {
    return (
        <div className="page_not_found">
            <h1>Page Not Found</h1>
        </div>
    );
};

export default PageNotFound;
