import React from 'react';
import './SixthSection.scss';
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/bundle";

const data = [
    {
        img: "/user1.png",
        firstName: "Fazliddin",
        position:"Генеральный директор",
        opinion:"Logistika kursi ajoyib edi. Ushbu dasturda talabalarni logistika sohasida ko'p tajribaga ega bo'lgan haqiqiy professionallar o'qitdi. Hozir men kompaniyaning bir qismi sifatida ishlayman. Shuning uchun men barcha Legal Jobs jamoasiga RAXMAT aytmoqchiman"
    },{
        img: "/user2.png",
        firstName: "UMIDJON",
        position:"Генеральный директор",
        opinion:"O'tgan 1 oy men uchun yaxshi va qiziqarli bo'ldi. Men logistikani boshlash uchun yetarli ma'lumotni o'rgandim. Darslar yaxshi va xizmat o'z vaqtida, sodda va to'g'ridan-to'g'ri edi. Hammasi uchun Behzod akaga raxmat."
    },{
        img: "/user3.png",
        firstName: "Shakhzod",
        position:"Генеральный директор",
        opinion:"Behzod akadan bir oy fantastik mashg'ulot oldik. Biz haqiqiy ishni boshlashga tayyor bo'lganimiz ajoyib, bu shunchaki mashg'ulot emas, balki ajoyib amaliyot va tajriba edi. Hammasi uchun Behzod akaga. Raxmat"
    },
    {
        img: "/user4.png",
        firstName: "Amirxon",
        position:"Генеральный директор",
        opinion:"O'quv kurslari juda foydali, men u yerda o'qish imkoniyatiga ega bo'lganimdan juda xursandman. Meni juda malakali va professional murabbiylar tayyorladilar. Raxmat"
    },{
        img: "/user5.png",
        firstName: "ABDUSHUKUR",
        opinion:"1 oylik treyning  ta'sirli va ma'lumotlarga boy bo'ldi. Men logistika va ta'minot zanjirini boshqarishning asosiy tushunchalari bilan tanishdim. Vaholanki, men jamoaning bizni qisqa vaqt ichida samarali o'qitish uchun qilgan munosabati va sa'y-harakatlarini chuqur qadrlayman."
    },
]

const SixthSection = () => {
    return (
        <div className="sixth_section">
            <SectionTitle title="Bizning bitiruvchilar"/>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    // when window width is >= 640px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    420: {
                        slidesPerView: 2,
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    800: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1336: {
                        slidesPerView: 4,
                        spaceBetween: 20
                    },
                    1800: {
                        slidesPerView: 5,
                        spaceBetween: 20
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                {data.map((v,i)=> {
                    return(
                <SwiperSlide key={i} className="py-2 px-3 mt-5">
                    <div className="swiper_cont">
                        <img src={v.img} alt=""/>
                        <div className="name"><p className="fw-bold">{v.firstName}</p></div>
                        <p className="opinion">{v.opinion}</p>
                    </div>
                </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
};

export default SixthSection;
