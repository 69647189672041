import React from 'react';
import "./SecondSection.scss";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import {Col, Row} from "antd";

const SecondSection = () => {
    return (
        <div className="second_section d-flex">
            <Row >
            <Col span={24} lg={12} className="text">
                <SectionTitle title={"Edutruck nima beradi"}/>
                <p><br/>
                    <span className="fw-bold">Amerika</span> yuk mashinalari bozori dunyodagi eng yirik hisoblanadi. Yiliga taxminan 800 milliard dollar, 100 000 ga yaqin kompaniya, 3,5 million haydovchi va 700 000 ga yaqin dispetcherlar. Talab doimiy ravishda oshib boradi.
                    <br/><br/>
                    <span className="fw-bold">Edutruck </span>kompaniyasi aynan Amerika bozori uchun har yili 1000ta kadr yetishtirib chiqarishni maqsad qilgan.
                    <br/><br/>
                    <span className="fw-bold">Edutruck </span> bilan siz Logistikaning yo’nalishlari bo’yicha professionallardan bilim olish imkoniga ega bo’lasiz. Agar siz o’rgatilgan bilimlarni mukammal darajada o’zlashtirsangiz, Sizni ish va 3.000 $ gacha bo’lgan maosh bilan o’zimiz taminlaymiz.
                </p>
            </Col>
            <Col span={24} lg={12} className="image">
                <img src="/wp3022734-canada-truck-wallpaper2.jpg" alt=""/>
            </Col>
            </Row>
        </div>
    );
};

export default SecondSection;
