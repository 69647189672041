import React, {useEffect} from 'react';
import './Courses.scss';
import {useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FirstSection from "./sections/FirstSection";
import datas from "./sections/data"
import SecondSection from "./sections/SecondSection";

const Courses = () => {
    const {courses} = useParams()
    const data = datas[courses]
    console.log(data)
    return (<>
        <Header/>
        <div className="courses_wrapper">
            <FirstSection data={data}/>
            <SecondSection data={data}/>
        </div>
        <Footer/>
    </>)
        ;
};

export default Courses;
